<template>
    <el-card style="min-height: 45rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form>
            <el-form-item label="访问域名">
              <el-input v-model="form.visit_domain"></el-input>
              <div class="y-desc">仅填写域名 不带http协议头 示例：www.baidu.com</div>
            </el-form-item>
            <el-form-item label="底部版权">
              <el-input v-model="form.copyright"></el-input>
            </el-form-item>
            <el-form-item label="ICP备案号">
              <el-input v-model="form.icp"></el-input>
            </el-form-item>
            <el-form-item label="站点LOGO">
              <y-upload-img v-model="form.logo"></y-upload-img>
            </el-form-item>
            <el-form-item label="登录按钮链接">
              <el-input v-model="form.login_link"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form>
            <el-form-item label="底部联系二维码">
              <y-upload-img v-model="form.bottom_link_qrcode"></y-upload-img>
            </el-form-item>
            <el-form-item label="底部联系电话">
              <el-input v-model="form.bottom_link_phone"></el-input>
            </el-form-item>
            <el-form-item label="底部联系qq">
              <el-input v-model="form.bottom_link_qq"></el-input>
              <div class="y-desc">多个用,分开 qq1,qq2,qq3</div>
            </el-form-item>
            <el-form-item label="首页标题">
              <el-input v-model="form.index_seo_title"></el-input>
            </el-form-item>
            <el-form-item label="首页关键字">
              <el-input v-model="form.index_seo_keywords"></el-input>
            </el-form-item>
            <el-form-item label="首页描述">
              <el-input v-model="form.index_seo_desc"></el-input>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
</template>

<script>
const EditFormTpl = {
  visit_domain:"",
  copyright:"",
  icp:"",
  logo:"",
  login_link:"",
  bottom_link_qrcode:"",
  bottom_link_phone:"",
  bottom_link_qq:"",
  index_seo_title:"",
  index_seo_keywords:"",
  index_seo_desc:"",
}


import YUploadImg from "@/components/y-form/y-upload/y_upload_img"

export default {
  name: "setting",
  components: {
    YUploadImg,
  },
  data() {
    return {
      form: {
        ...EditFormTpl
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.website.setting().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.website.settingEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>